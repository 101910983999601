body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

video {
  max-height: calc(100vh - 65px);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink-blue {
  0% {
    color: rgba(0, 219, 255, 0);
  }
  100% {
    color: rgba(0, 219, 255, 1);
  }
}

@keyframes blink-blue {
  0% {
    color: rgba(0, 219, 255, 0);
  }
  100% {
    color: rgba(0, 219, 255, 1);
  }
}
